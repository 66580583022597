$primary: #6892F4 !default;
$brand-primary: #6892F4 !default;

$enable-negative-margins: true;

$background-color-active: #f1f1f1;
$background-color: #ECF2F7;
$sidebar-background: #fff;

$sidebar-width: 275px;


.nav-link.active {
    box-shadow: 4px 0px inset $primary;
    color: $primary !important;
}

#sidebar {
    .nav-link:hover {
        background-color: $background-color;
    }

    .nav-link {
        display: flex;
        flex: 1;
        align-items: center;
        padding: .8445rem 1rem;
        text-decoration: none;
        white-space: nowrap;
        background: transparent;
        transition: background .15s ease,
            color .15s ease;
    }

    .active {
        background-color: $background-color-active;
        color: $primary;
    }

    border-right: 1px solid #CFD2D9;
    background-color: $sidebar-background;
    box-shadow: 0px 0em 3em #ccc;
    z-index: 1050;
}

.sidebar-nav {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
}

* {
    font-size: inherit;
}


$primary: #6892F4 !default;
$brand-primary: $primary;

@import 'node_modules/bootstrap/scss/bootstrap';


.btn-primary {
    color: $white;
}

.nav-link {
    color: black !important;
}

.control-link:hover {
    background-color: $gray-300;
}

.form-floating .ng-select.ng-select-single .ng-select-container {
    height: 20px;
}

.form-floating .ng-select .ng-select-container {
    min-height: 20px;
    border: none;
}

.form-floating .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-left: 0;
}

.form-floating>.ng-select.form-control,
.form-floating>.form-select {
    min-height: calc(3.5rem + 2px) !important;
    height: inherit !important;
    line-height: 1.25;
}

.ng-select [class^='ri'] {
    position: relative;
    top: 3px;
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #D9D8Df !important;
}


.ng-select [class^="ri"] {
    position: relative;
    top: 3px;
}


.form-control {
    background-color: $gray-200 !important;
}

.form-control[ng-reflect-is-disabled="true"] {
    background-color: $gray-100 !important;
}

label::after {
    background-color: $gray-200 !important;
}

.form-floating>.form-control:disabled~label::after {
    background-color: $gray-100 !important;
}

.table-hover>tbody>tr:hover>* {
    background-color: $gray-100;
}

.ng-select-container {
    background-color: $gray-200 !important;
}

.ng-option {
    background-color: $gray-200 !important;
}

.ng-option-selected {
    background-color: $primary !important;
}

.ng-select-container {
    border: none !important;
    box-shadow: none !important;
}